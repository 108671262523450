<script setup>

</script>

<template>
  <footer>
    <ul class="footerBox">
      <li class="li">
        <ul>
          <li>备0000000000000号</li>
          <li>FUNCTIONIN THEME MADE BY FUNCTIONIN</li>
          <li>COPYRIGHT © 2023 FUNCTIONIN.CN. ALL RIGHTS RESERVED.</li>
        </ul>
      </li>
    </ul>
  </footer>
</template>

<style scoped>
/* Default Style */
@import "defaultStyle.css";
/* Media Style */
@import "mediaStyle.css";
</style>