import {createRouter, createWebHistory} from "vue-router";
import HomeView from "@/pages/homeView/homeView.vue";


const routes = [
    // 首页路由
    {path: '/', name: 'home', component: HomeView,},
];

const router = createRouter({
    history: createWebHistory(), routes
});
export default router;